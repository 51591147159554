import React from 'react';
import { render } from 'react-dom';
import './index.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './app/App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>,
  document.querySelector('#root')
);
