import ListItem from '@mui/material/ListItem';
import React from 'react';

import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';

const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  width: '100%',
  height: '48px',
  padding: '12px 16px',
  lineHeight: '24px',
  color: active
    ? `${theme.palette.primary.main}${theme.palette.other.opacityHigh}`
    : `${theme.palette.surface.text}${theme.palette.other.opacityHigh}`,
  transition: 'background-color 0.1s',
  borderRadius: '4px',
  cursor: !active ? 'pointer' : undefined,
  backgroundColor: active ? `${theme.palette.primary.main}30` : undefined,
  '&:hover': {
    backgroundColor: !active ? `${theme.palette.primary.main}10` : undefined,
  },
}));

interface Props {
  id: number;
  name: string | React.ReactNode;
  active: number;
  onClick: () => void;
}

/* 
  Combines two classes if active, else only show root class 
*/
const SidebarItem: React.FC<Props> = ({ name, id, active, onClick }) => {
  return (
    <StyledListItem active={id === active} onClick={onClick}>
      <Typography variant="body1">{name}</Typography>
    </StyledListItem>
  );
};

export default SidebarItem;
