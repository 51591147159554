import axios from 'axios';

export type BackendResponse<T> = {
  data: {
    data: T;
  };
};

type ObjectType = {
  [key: string]: any;
};

const sendRequest = async <T>(config: ObjectType = {}): Promise<BackendResponse<T>> =>
  axios<BackendResponse<T>>(config)
    .then((req: any) => req)
    .catch((err: any) => {
      return Promise.reject(err);
    });

const rawRequest = async <T>(
  method: string,
  endpoint: string,
  params?: ObjectType,
  contentType: string = 'application/json',
  data: ObjectType = {},
  conf: ObjectType = {}
): Promise<BackendResponse<T>> => {
  const BASEURL = `${process.env.API_ADDRESS}:${process.env.API_PORT}`;
  const config = { ...conf };
  config.headers = { 'Content-Type': contentType };
  if (localStorage.token != null) {
    config.headers.Authorization = `Bearer ${localStorage.token}`;
  }
  config.baseURL = BASEURL;
  config.method = method;
  config.url = endpoint;
  config.params = params;
  config.data = data;
  return sendRequest<T>(config);
};

export const request = async <T = any>(
  method: string,
  endpoint: string,
  params?: ObjectType,
  data?: ObjectType,
  contentType: string = 'application/json'
): Promise<BackendResponse<T>> => rawRequest<T>(method, endpoint, params, contentType, data, {});

export const requestBlob = (
  method: string,
  endpoint: string,
  params?: ObjectType,
  data?: ObjectType,
  contentType: string = 'application/json'
): Promise<any> => {
  const config: ObjectType = {};
  config.responseType = 'blob';
  return rawRequest(method, endpoint, params, contentType, data, config);
};
