import React from 'react';
import MenuButton from './MenuButton';
import useFetchImage from 'hooks/useFetchImages';
import logo from '/static/logo_100.png';

interface LogoButtonProps {
  page: string;
  onClick: () => void;
}

const LogoButton: React.FC<LogoButtonProps> = ({ page, onClick }) => {
  const imageUrl = useFetchImage('Logo+Desktop', logo);
  return (
    <MenuButton page={page} onClick={onClick} name="">
      <img src={imageUrl} alt="ths-logo" style={{ width: '48px', height: '48px' }} />
    </MenuButton>
  );
};

export default LogoButton;
