import { List } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SidebarItem from './sidebarItem/SidebarItem';

interface Props {
  items?: Record<string, string>;
}

const SidebarList: React.FC<Props> = ({ items }) => {
  const [activeSideItem, setActiveSideItem] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!items) return;
    Object.values(items).forEach((value, index) => {
      if (location?.pathname.includes(value)) setActiveSideItem(index);
    });
  }, [location, location?.pathname, items]);

  if (!items) return <></>;

  return (
    <List dense className="list">
      {Object.keys(items).map((key, i) => (
        <SidebarItem
          key={`sidebar-item-${key}`}
          id={i}
          name={key}
          active={activeSideItem}
          onClick={() => {
            setActiveSideItem(i);
            navigate(items[key]);
          }}
        />
      ))}
    </List>
  );
};

export default SidebarList;
