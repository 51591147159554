import { styled } from '@mui/material';
import React from 'react';

const Root = styled('section')(({ theme }) => ({
  width: '100%',
  height: '64px',
  padding: '0 24px',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    height: '56px',
    padding: '0',
  },
}));

interface NavContainerProps {
  left: React.ReactNode;
  right: React.ReactNode;
}

const NavContainer: React.FC<NavContainerProps> = ({ left, right }) => {
  return (
    <Root>
      <div style={{ display: 'flex' }}>{left}</div>
      <div style={{ display: 'flex' }}>{right}</div>
    </Root>
  );
};

export default NavContainer;
