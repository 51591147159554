import { Grid, styled } from '@mui/material';
import React from 'react';

const RootGrid = styled(Grid)(({ theme }) => ({
  margin: '0 auto',
  maxWidth: theme.breakpoints.values.lg,
}));

interface Props {
  children: React.ReactNode;
}

const AppContent = ({ children }: Props) => {
  return (
    <RootGrid container justifyContent="center">
      {children}
    </RootGrid>
  );
};

export default AppContent;
