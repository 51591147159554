import React from 'react';

import HomeIcon from '@mui/icons-material/Home';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import EventIcon from '@mui/icons-material/Event';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { Route } from 'types/types';

const routesList: Route[] = [
  {
    name: 'Home',
    icon: <HomeIcon />,
    location: 'middle',
  },
  {
    name: 'Events',
    icon: <EventIcon />,
    location: 'middle',
  },
  {
    name: 'Tickets',
    icon: <ConfirmationNumberIcon />,
    location: 'middle',
  },
  {
    name: 'Admin',
    icon: <SettingsIcon />,
    location: 'right',
  },
  {
    name: 'Profile',
    icon: <AccountCircleIcon />,
    location: 'right',
  },
  {
    name: 'Login',
    icon: <ExitToAppIcon />,
    location: 'right',
  },
  {
    name: 'Logout',
    icon: <ExitToAppIcon />,
    location: 'right',
  },
];

export default routesList;
