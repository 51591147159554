import { useQuery } from '@tanstack/react-query';
import useLoggedIn from 'hooks/useLoggedIn';
import useProjectId from 'hooks/useProjectId';
import { userInProject } from 'queries/user/user-in-project';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Route } from 'types/types';
import routesList from '../RouteList';

const ADMIN_ROUTES = ['Home', 'Events', 'Tickets', 'Profile', 'Logout', 'Admin'];
const STUDENT_ROUTES = ['Home', 'Events', 'Tickets', 'Profile', 'Logout'];
const NO_PROJECT_ROUTES = ['Logout'];
const EMPTY_ROUTES = ['Login'];

const useAccessRoutes = () => {
  const location = useLocation();
  const projectId = useProjectId();
  const loggedIn = useLoggedIn();
  const inProject = useQuery(userInProject({ projectId, loggedIn }));
  const [availableRoutes, setAvailableRoutes] = useState<Route[]>([]);
  const [page, setPage] = useState('');

  const getRoutes = (token: string | null): string[] => {
    if (!token) {
      return EMPTY_ROUTES;
    }
    if (!inProject.data?.isSigned) {
      return NO_PROJECT_ROUTES;
    }

    const roleID = JSON.parse(atob(token.split('.')[1])).projectRole;
    if (roleID < 6) {
      return ADMIN_ROUTES;
    }
    return STUDENT_ROUTES;
  };

  useEffect(() => {
    try {
      const split = location.pathname.substring(2).slice(1).toLocaleLowerCase();
      const split2 = split.includes('/') ? split.split('/')[0] : split;
      setPage(split2);
    } catch (e) {
      setPage('');
    }
  }, [location.pathname]);

  useEffect(() => {
    setAvailableRoutes(routesList.filter((x) => getRoutes(localStorage.getItem('token'))?.includes(x.name)));
  }, [localStorage.getItem('token'), inProject.data?.isSigned]);

  return { availableRoutes, page };
};

export default useAccessRoutes;
