import React from 'react';

import { useNavigate } from 'react-router-dom';

import { useSubMenuContext } from 'context/SubMenuContext';
import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import useAccessRoutes from './hooks/useAccessRoutes';
import useDrawer from './hooks/useDrawer';
import BurgerButton from '/components/navbar/menu-button/BurgerButton';
import LogoButton from '/components/navbar/menu-button/LogoButton';
import TextButton from '/components/navbar/menu-button/TextButton';
import MobileLocation from '/components/navbar/mobile-location/MobileLocation';
import NavContainer from '/components/navbar/nav-container/NavContainer';
import NavDrawer from '/components/navbar/nav-drawer/NavDrawer';
import NavDrawerButton from '/components/navbar/nav-drawer/NavDrawerButton';

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const { isSubMenu } = useSubMenuContext();
  const projectId = useProjectId();

  const { open, handleToggle } = useDrawer();
  const { availableRoutes, page } = useAccessRoutes();

  const navigateTo = (path: string) => {
    navigate(`/${projectId}/${path.toLowerCase()}`);
  };

  if (isMobile && isSubMenu) {
    return <></>;
  }

  return (
    <>
      {isMobile && (
        <NavDrawer open={open} toggleDrawer={handleToggle}>
          {availableRoutes.map((r) => (
            <NavDrawerButton
              key={`drawer-button-${r.name}`}
              page={page}
              label={r.name}
              icon={r.icon}
              onClick={() => {
                navigateTo(r.name);
              }}
            />
          ))}
        </NavDrawer>
      )}
      <NavContainer
        left={
          <>
            {isMobile && <BurgerButton onClick={handleToggle} />}
            {isMobile && <MobileLocation location={page} routes={availableRoutes} />}
            {!isMobile && <LogoButton page={page} onClick={() => navigateTo('')} />}
            {!isMobile &&
              availableRoutes
                .filter((r) => r.location === 'middle' && r.name !== 'Home')
                .map((r) => (
                  <TextButton
                    key={`navbar-button-text-${r.name}`}
                    page={page}
                    onClick={() => navigateTo(r.name)}
                    label={r.name}
                  />
                ))}
          </>
        }
        right={
          <>
            {isMobile && <LogoButton page={page} onClick={() => navigateTo('')} />}
            {!isMobile &&
              availableRoutes
                .filter((r) => r.location === 'right')
                .map((r) => (
                  <TextButton
                    key={`navbar-button-text-${r.name}`}
                    page={page}
                    onClick={() => navigateTo(r.name)}
                    label={r.name}
                  />
                ))}
          </>
        }
      />
    </>
  );
};

export default Navbar;
