import React from 'react';
import SidebarList from './SidebarList';

interface Props {
  items?: Record<string, string>;
  sidebarComponent?: React.ReactNode;
}

// items hold value and label.
const Sidebar: React.FC<Props> = ({ items, sidebarComponent }) => {
  return (
    <div style={{ backgroundColor: 'white', top: '40px' }}>
      {sidebarComponent}
      <SidebarList items={items} />
    </div>
  );
};

export default Sidebar;
