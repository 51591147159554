import { useQuery } from '@tanstack/react-query';
import getPublicText from 'queries/texts/get-public-text';
import { PublicProjectTexts } from 'types/types';
import useProjectId from './useProjectId';

interface ExportProps {
  texts: PublicProjectTexts;
  isLoading: boolean;
}

const useFetchTexts = (): ExportProps => {
  const projectId = useProjectId();
  const { data, isLoading } = useQuery(getPublicText(projectId));

  if (isLoading || !data) {
    return {
      texts: {
        PROJECT_RULES: '',
        PROJECT_NAME: '',
        FRONTPAGE_MAIN: '',
      },
      isLoading: true,
    };
  }

  return { texts: data, isLoading };
};

export default useFetchTexts;
