import { Button, styled } from '@mui/material';
import useLoggedIn from 'hooks/useLoggedIn';
import useProjectId from 'hooks/useProjectId';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const RegisterButton = styled(Button)(({ theme }) => ({
  width: '97px',
  marginRight: '51px',
  [theme.breakpoints.down('sm')]: {
    marginRight: '27px',
  },
}));

const LoginButton = styled(Button)(({ theme }) => ({
  width: '97px',
  marginLeft: '51px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '27px',
  },
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  margin: '0 auto',
  width: '297px',
  marginTop: '456px',
  [theme.breakpoints.down('md')]: {
    marginTop: '383px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '248px',
    marginTop: '295px',
  },
}));

const JumbotronButtons: React.FC = () => {
  const navigate = useNavigate();
  const projectId = useProjectId();
  const loggedIn = useLoggedIn();

  if (!loggedIn)
    return (
      <ButtonContainer>
        <RegisterButton color="secondary" variant="contained" onClick={() => navigate(`/${projectId}/createaccount`)}>
          Register
        </RegisterButton>
        <LoginButton color="secondary" variant="contained" onClick={() => navigate(`/${projectId}/login`)}>
          Login
        </LoginButton>
      </ButtonContainer>
    );

  return <ButtonContainer />;
};

export default JumbotronButtons;
