import useFetchTexts from 'hooks/useFetchTexts';
import React, { createContext, useContext } from 'react';
import { PublicProjectTexts } from 'types/types';

const emptyProjectTexts: PublicProjectTexts = {
  PROJECT_RULES: '',
  PROJECT_NAME: '',
  FRONTPAGE_MAIN: '',
};

export const ProjectTextContext = createContext(emptyProjectTexts);

export const useProjectTextContext = (): PublicProjectTexts => useContext(ProjectTextContext);

export const ProjectTextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { texts } = useFetchTexts();

  return <ProjectTextContext.Provider value={texts}>{children}</ProjectTextContext.Provider>;
};
