import useProjectId from 'hooks/useProjectId';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface PrivateRouteProps {
  children: React.ReactNode;
  admin?: boolean;
}

/**
 * Makes a route private by checking everytime if the user has the required
 * permissions to view a certain route. otherwise it sends the user into
 * the home page
 */
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, admin = false }) => {
  const projectId = useProjectId();
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate(`/${projectId}/`);
  };

  useEffect(() => {
    try {
      const { token } = localStorage;
      if (!token) {
        throw new Error('No token found');
      }
      const roleID = JSON.parse(atob(token?.split('.')[1]))?.projectRole;
      if (roleID >= 6 && admin) {
        navigateHome();
      }
    } catch (err) {
      navigateHome();
      localStorage.removeItem('token');
    }
  }, []);

  return <>{children}</>;
};

export default PrivateRoute;
