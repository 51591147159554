import { Grid } from '@mui/material';
import React from 'react';
import Sidebar from './sidebar/Sidebar';

interface Props {
  sidebar?: Record<string, string>;
  sidebarComponent?: React.ReactNode;
}

const SidebarContainer: React.FC<Props> = ({ sidebar, sidebarComponent }) => {
  const sidebarExists = sidebar || sidebarComponent;

  if (!sidebarExists) {
    return <></>;
  }

  return (
    <Grid item md={3}>
      <Sidebar items={sidebar} sidebarComponent={sidebarComponent} />
    </Grid>
  );
};

export default SidebarContainer;
