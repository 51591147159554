import React from 'react';

import BottomFooter from './BottomFooter';
import MiddleFooter from './MiddleFooter';
import TopFooter from './TopFooter';

const Footer: React.FC = () => {
  return (
    <footer style={{ width: '100%' }}>
      <TopFooter />
      <MiddleFooter />
      <BottomFooter />
    </footer>
  );
};

export default Footer;
