import { styled } from '@mui/material';
import React from 'react';
import Twemoji from 'twemoji';

const TwemojiContainer = styled('div')(() => ({
  '& img.emoji': {
    height: '1.25em',
    width: '1.25em',
    margin: '0 .05em 0 .1em',
    verticalAlign: '-0.1em',
  },
}));

interface Props {
  children: React.ReactNode;
  [key: string]: any;
}

const Emojify: React.FC<Props> = ({ children, ...props }) => {
  const [ref] = React.useState(React.createRef<HTMLDivElement>());
  React.useEffect(() => {
    if (ref.current) Twemoji.parse(ref.current);
  }, [children, ref]);
  return (
    <TwemojiContainer ref={ref} {...props}>
      {children}
    </TwemojiContainer>
  );
};

export default Emojify;
