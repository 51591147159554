import React from 'react';

import { useProjectTextContext } from 'context/ProjectTextContext';
import Content from '/components/content/Content';
import Jumbotron from '/components/jumbotron/Jumbotron';
import ReactMarkdown from 'react-markdown';
import Emojify from 'components/emojify/Emojify';
import Footer from '../../components/footer/Footer';

const Home: React.FC = () => {
  const { FRONTPAGE_MAIN: text } = useProjectTextContext();
  return (
    <>
      <Jumbotron />
      <Content>
        <Emojify>
          <ReactMarkdown>{text ?? ''}</ReactMarkdown>
        </Emojify>
      </Content>
      <Footer />
    </>
  );
};

export default Home;
