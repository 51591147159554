import React from 'react';
import { ListItem, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const CustomListItem = withStyles((t) => ({
  root: {
    fontSize: '24px',
    height: '48px',
    paddingTop: t.spacing(1),
    paddingBottom: t.spacing(1),
    paddingLeft: '8px',
    color: `#00000087`,
    ' & > p': {
      margin: '0',
      paddingLeft: '28px',
      lineHeight: 'inherit',
    },
    '&:active, &:hover': {
      backgroundColor: `${t.palette.primary.main}12 !important`,
      color: `${t.palette.primary.main} !important`,
    },
  },
  selected: {
    backgroundColor: `${t.palette.primary.main}12 !important`,
    color: t.palette.primary.main,
  },
}))(ListItem);

interface NavDrawerButtonProps {
  page: string;
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
}

const NavDrawerButton: React.FC<NavDrawerButtonProps> = ({ page, label, icon, onClick }) => {
  const isActive = (): boolean => {
    return page.toLocaleLowerCase() === label.toLocaleLowerCase();
  };

  return (
    <CustomListItem selected={isActive()} onClick={onClick}>
      {icon}
      <Typography variant="body1">{label}</Typography>
    </CustomListItem>
  );
};

export default NavDrawerButton;
