import { Theme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import theme from 'MuiTheme';
import getTheme from 'queries/theme/get-theme';
import useProjectId from './useProjectId';

interface ExportProps {
  theme: Theme;
  isLoading: boolean;
}

const useFetchTheme = (): ExportProps => {
  const projectId = useProjectId();
  const { data, isLoading } = useQuery(getTheme(projectId));

  if (isLoading || !data) {
    return { theme, isLoading };
  }

  const newTheme = { ...theme };
  newTheme.palette.primary = data.primary;
  newTheme.palette.secondary = data.secondary;
  newTheme.palette.error = data.error;
  newTheme.palette.success = data.success;
  newTheme.palette.warning = data.warning;
  newTheme.palette.info = data.info;

  return { theme: newTheme, isLoading };
};

export default useFetchTheme;
