import { Typography } from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../static/logo_100.png';

const MiddleFooter: React.FC = () => {
  const isMobile = useIsMobile();
  const projectId = useProjectId();

  return (
    <div
      style={{
        backgroundColor: '#616161',
        height: isMobile ? 'auto' : '35px',
        padding: isMobile ? '8px 16px' : '0 24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: isMobile ? 'flex-start' : 'center',
          gap: isMobile ? '4px' : '8px',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Link to={{ pathname: `/${projectId}/about/aboutus` }} target="_blank" style={{ textDecoration: 'none' }}>
          <Typography variant={isMobile ? 'body2' : 'body1'} color="white">
            Learn More{!isMobile && <>&nbsp;&nbsp;|</>}
          </Typography>
        </Link>
        <Link to={{ pathname: `/${projectId}/about/terms` }} target="_blank" style={{ textDecoration: 'none' }}>
          <Typography variant={isMobile ? 'body2' : 'body1'} color="white">
            Terms of Use{!isMobile && <>&nbsp;&nbsp;|</>}
          </Typography>
        </Link>
        <Link to={{ pathname: `/${projectId}/about/privacy` }} target="_blank" style={{ textDecoration: 'none' }}>
          <Typography variant={isMobile ? 'body2' : 'body1'} color="white">
            Privacy Policy{!isMobile && <>&nbsp;&nbsp;|</>}
          </Typography>
        </Link>
        <Link to={{ pathname: `/${projectId}/about/additional` }} target="_blank" style={{ textDecoration: 'none' }}>
          <Typography variant={isMobile ? 'body2' : 'body1'} color="white">
            Additional Communication
          </Typography>
        </Link>
      </div>
      <img
        src={logo}
        alt="ths-logo"
        style={{ width: isMobile ? '50px' : '24px', height: isMobile ? '50px' : '24px' }}
      />
    </div>
  );
};

export default MiddleFooter;
