import { Typography } from '@mui/material';
import React from 'react';
import useDynamicTheme from 'hooks/useDynamicTheme';
import MenuButton from './MenuButton';

interface TextButtonProps {
  page: string;
  onClick: () => void;
  label: string;
}

const TextButton: React.FC<TextButtonProps> = ({ label, page, onClick }) => {
  const theme = useDynamicTheme();
  return (
    <MenuButton page={page} onClick={onClick} name={label}>
      <Typography variant="h6" color={theme.palette.primary.contrastText}>
        {label}
      </Typography>
    </MenuButton>
  );
};

export default TextButton;
