import * as Backend from '/queries/BackendRequest';

const fetchImage = async (pid: string, key: string): Promise<any> => {
  const result = await Backend.requestBlob('GET', `/content-public/${pid}/theme/images?key=${key}`);
  return result.data;
};

const getImage = (pid: string, key: string) => ({
  queryKey: ['getImage', pid, key],
  queryFn: () => fetchImage(pid, key),
  enabled: !!pid,
  cacheTime: 1000 * 60 * 60, // 1 hour
  retry: 1,
  retryDelay: 20000,
});

export default getImage;
