import { useQuery } from '@tanstack/react-query';
import getImage from 'queries/theme/get-image';
import useProjectId from './useProjectId';

const useFetchImage = (key: string, logo: any) => {
  const projectId = useProjectId();
  const { data, isLoading } = useQuery(getImage(projectId, key));

  if (isLoading || !data) {
    return logo;
  }
  const url = URL.createObjectURL(data);
  return url;
};

export default useFetchImage;
