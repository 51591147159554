import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Route } from 'types/types';

const routesList = [
  {
    url: 'createaccount',
    name: 'Register',
  },
  {
    url: 'about',
    name: 'About',
  },
  {
    url: 'forgot',
    name: 'Forgot password',
  },
  {
    url: 'reset',
    name: 'Forgot password',
  },
  {
    url: 'sentforgot',
    name: 'Forgot password',
  },
  {
    url: 'projectinfo',
    name: 'Project rules',
  },
  {
    url: '',
    name: 'Home',
  },
];

interface MobileLocationProps {
  location: string;
  routes: Route[];
}

const MobileLocation: React.FC<MobileLocationProps> = ({ location, routes }) => {
  const [displayText, setDisplayText] = React.useState('');

  const setCurrentlocation = () => {
    let set = false;

    routes.forEach((route) => {
      if (route.name.toLowerCase() === location.toLowerCase()) {
        // passed routes
        set = true;
        setDisplayText(route.name);
      }
    });

    if (!set) {
      routesList.forEach((route) => {
        if (set) return;
        if (location.toLowerCase().includes(route.url)) {
          set = true;
          setDisplayText(route.name);
        }
      });
    }

    if (!set)
      // if nothing is found
      setDisplayText('');
  };

  useEffect(() => {
    setCurrentlocation();
  }, [location, routes]);

  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
      <Typography variant="h6" style={{ color: 'white', marginLeft: '0.5rem', fontWeight: 400 }}>
        {displayText}
      </Typography>
    </div>
  );
};

export default MobileLocation;
