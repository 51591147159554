import { useState } from 'react';

const useDrawer = () => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return { open, handleToggle };
};

export default useDrawer;
