import { Button, styled } from '@mui/material';
import React from 'react';

const CustomButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  color: theme.palette.surface.main,
  borderRadius: 0,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingTop: 0,
  paddingBottom: 0,
  backgroundColor: active ? `${theme.palette.background.main}${theme.palette.overlay.opacity.activated}` : undefined,
  '&:hover': {
    backgroundColor: `${theme.palette.background.main}${theme.palette.overlay.opacity.hover}`,
  },
}));

interface MenuButtonProps {
  name: string;
  page: string;
  children: React.ReactNode;
  onClick: () => void;
}

const MenuButton: React.FC<MenuButtonProps> = ({ page, name, children, onClick }) => {
  const isActive = (): boolean => {
    return page.toLocaleLowerCase() === name.toLocaleLowerCase();
  };

  return (
    <CustomButton active={isActive()} disableRipple disableElevation onClick={onClick}>
      {children}
    </CustomButton>
  );
};

export default MenuButton;
