import { Divider, Grid } from '@mui/material';
import React from 'react';

interface Props {
  header?: React.ReactNode;
}

const ContentHeader: React.FC<Props> = ({ header }) => {
  if (!header) return <></>;

  return (
    <Grid item md={12} xs={12}>
      <div style={{ marginTop: '16px' }}>
        {header}
        <Divider style={{ marginTop: '16px' }} />
      </div>
    </Grid>
  );
};

export default ContentHeader;
