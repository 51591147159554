import React from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import MenuButton from './MenuButton';

interface BurgerButtonProps {
  onClick: () => void;
}

const BurgerButton: React.FC<BurgerButtonProps> = ({ onClick }) => {
  return (
    <MenuButton page="oaisndoiansdoiansdoiansdoidan" onClick={onClick} name="">
      <MenuIcon style={{ width: '24px' }} />
    </MenuButton>
  );
};

export default BurgerButton;
