import React from 'react';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import MailIcon from '@mui/icons-material/Mail';
import { IconButton, Typography, styled } from '@mui/material';
import useDynamicTheme from 'hooks/useDynamicTheme';
import useIsMobile from 'hooks/useIsMobile';
import { useProjectTextContext } from 'context/ProjectTextContext';

const CustomIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 't',
})<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  width: isMobile ? '30px' : '24px',
  height: isMobile ? '30px' : '24px',
  padding: 0,
  color: theme.palette.primary.contrastText,
}));

const TopFooter: React.FC = () => {
  const theme = useDynamicTheme();
  const isMobile = useIsMobile();
  const { PROJECT_NAME: name } = useProjectTextContext();

  return (
    <div
      style={{
        backgroundColor: theme.palette.primary.main,
        height: isMobile ? '56px' : '45px',
        padding: isMobile ? '0 16px' : '0 24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {!isMobile && (
        <Typography
          variant="body1"
          style={{
            color: theme.palette.primary.contrastText,
          }}
        >
          {name}
        </Typography>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: isMobile ? 0 : '80px',
          flexGrow: isMobile ? 1 : 0,
          justifyContent: isMobile ? 'space-between' : 'initial',
        }}
      >
        <CustomIconButton isMobile={isMobile} onClick={() => window.open('https://www.thsint.se', '_blank')}>
          <LanguageIcon />
        </CustomIconButton>
        <CustomIconButton isMobile={isMobile} onClick={() => window.open('mailto:info@thsint.se', '_blank')}>
          <MailIcon />
        </CustomIconButton>
        <CustomIconButton isMobile={isMobile} onClick={() => window.open('https://www.facebook.com/thsint', '_blank')}>
          <FacebookIcon />
        </CustomIconButton>
        <CustomIconButton
          isMobile={isMobile}
          onClick={() => window.open('https://www.instagram.com/ths_international_reception', '_blank')}
        >
          <InstagramIcon />
        </CustomIconButton>
      </div>
    </div>
  );
};

export default TopFooter;
