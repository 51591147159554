import { useMediaQuery } from '@mui/material';
import { Breakpoint } from '@mui/material/styles';
import useDynamicTheme from './useDynamicTheme';

const useIsMobile = (breakpoint: Breakpoint = 'md') => {
  const theme = useDynamicTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(breakpoint));
  return isMobile;
};

export default useIsMobile;
