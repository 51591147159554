import useProjectId from 'hooks/useProjectId';
import React from 'react';
import { Navigate } from 'react-router-dom';

const RedirectPage: React.FC = () => {
  const projectId = useProjectId();
  return <Navigate to={`/${projectId}/`} replace />;
};

export default RedirectPage;
