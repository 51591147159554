import { Typography } from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react';

const BottomFooter: React.FC = () => {
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        backgroundColor: '#373737',
        height: '30px',
        padding: '0 24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant={isMobile ? 'body2' : 'body1'} color="white">
        © THS International 2024 - All rights reserved
      </Typography>
    </div>
  );
};

export default BottomFooter;
